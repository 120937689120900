import React from "react";
import styles from "./.module.scss";
import RankCard from "./RankCard";
import LoadingWrapper from "../LoadingWrapper";
import { BiErrorCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";

export default function TopRanked({ theme, topRankings, loading }) {
  const { t } = useTranslation();

  return (
    <div className={styles.top__ranked__wrapper}>
      <img
        className={styles.top__border}
        src={`/assets/svg/${theme}_border.svg`}
        alt="border"
      />
      <div className={styles.cards__wrapper}>
        {loading && <LoadingWrapper theme={theme} />}
        {!loading && (
          <>
            {topRankings?.data?.length ? (
              <>
                {topRankings?.data?.filter((i) => i.rank === 2)[0] && (
                  <RankCard
                    rank={topRankings?.data?.filter((i) => i.rank === 2)[0]}
                  />
                )}
                {topRankings?.data?.filter((i) => i.rank === 1)[0] && (
                  <RankCard
                    rank={topRankings?.data?.filter((i) => i.rank === 1)[0]}
                  />
                )}
                {topRankings?.data?.filter((i) => i.rank === 3)[0] && (
                  <RankCard
                    rank={topRankings?.data?.filter((i) => i.rank === 3)[0]}
                  />
                )}
              </>
            ) : (
              <div className={styles.no__content}>
                <BiErrorCircle className={styles.icon} />
                <span>{t("no_data")}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
