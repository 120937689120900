import API from "../../api";
import { GET__USER__LEVEL } from "../../constants";

const getUesrLevel = (currentTab) => async (dispatch) => {
  const setParam = () => {
    switch (currentTab) {
      case "personal":
        return "/personal-level";
      case "charge":
        return "/charge-level";
      case "carisma":
        return "/user-carisma-level";
      default:
        break;
    }
  };
  try {
    const res = await API.get(setParam());
    dispatch({
      type: GET__USER__LEVEL,
      payload: res?.data,
    });
  } catch (error) {
    dispatch({
      type: GET__USER__LEVEL,
      payload: error?.response?.data || {},
    });
  }
};
export default getUesrLevel;
