import React from "react";
import styles from "./.module.scss";
import approx from "approximate-number";

export default function RankCard({ rank }) {
  return (
    <div className={styles.rank__card}>
      <img
        className={styles.rank__bg}
        src={`/assets/svg/bg_rank${rank?.rank}.svg`}
        alt="bg"
      />
      <div className={styles.card__content}>
        <div className={styles.rank__frame}>
          <div className={styles.frame}>
            <img src={`/assets/svg/framerank${rank?.rank}.svg`} alt="frame" />
          </div>
          <div className={styles.user__avatar}>
            <img
              src={
                rank?.room_uploaded_avatar ||
                rank?.user?.profile_photo ||
                // rank?.user_avatar_path ||
                // rank?.receiver_avatar_path ||
                // rank?.user?.room_uploaded_avatar ||
                // rank?.user?.user_avatar_path ||
                // rank?.user?.receiver_avatar_path ||
                // rank?.profile_photo ||
                // rank?.user?.profile_photo ||
                `/assets/svg/user.png`
              }
              alt="user"
            />
          </div>
        </div>
        <p className={styles.user__name}>
          {rank?.room_name || rank?.receiver_name || rank?.user_name}
        </p>
        <div className={styles.achievements}>
          {rank?.userBadges?.map((item) => (
            <span key={item}>
              <img src={item?.badge_icon || item?.badge_img} alt="icon" />
            </span>
          ))}
        </div>
        <div className={styles.coins}>
          <p className={styles.value}>
            {approx(rank?.total_coins, { capital: true })}
          </p>
          <img src="/assets/svg/icons/coin.svg" alt="coins" />
        </div>
      </div>
    </div>
  );
}
