import { combineReducers } from "redux";

import userWalletAddress from "./userWalletReducer";
import rechargePackagesReducer from "./rechargePackagesReducer";
import userTransactionsReducer from "./userTransactionsReducer";
import userLevelReducer from "./userLevelReducer";
import userBadgesReducer from "./userBadgesReducer";
import badgeDetailsReducer from "./badgeDetailsReducer";
import topRankingsReducer from "./topRankingsReducer";

export default combineReducers({
  userWallet: userWalletAddress,
  rechargePackages: rechargePackagesReducer,
  userTransactions: userTransactionsReducer,
  userLevel: userLevelReducer,
  userBadges: userBadgesReducer,
  badgeDetails: badgeDetailsReducer,
  topRankings: topRankingsReducer,
});
