import API from "../../api";
import { GET__ALL__RECHARGE__PACKAGES } from "../../constants";

const getAllRechargePackages = () => async (dispatch) => {
  try {
    const res = await API.get(`/package/get-all`);
    dispatch({
      type: GET__ALL__RECHARGE__PACKAGES,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET__ALL__RECHARGE__PACKAGES,
      payload: error.response.data || [],
    });
  }
};
export default getAllRechargePackages;
