import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import UserInfoHeader from "../../components/UserInfoHeader";
import Wrapper from "../../components/Wrapper";
import NavigationBar from "../../components/NavigationBar";
import UserLevelProgressBar from "../../components/UserLevelProgressBar";
import UpgradeMethodCard from "../../components/UpgradeMethodCard";

import { getUserLevel } from "../../redux/actions";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { getUserLevel };

const upgradeLevelData = [
  { level: "charge", data: [{ coin: 1, xp: 1, method: "charge_coins" }] },

  {
    level: "personal",
    data: [{ coin: 1, xp: 1, method: "send_a_gift" }],
  },
  {
    level: "carisma",
    data: [{ coin: 1, xp: 1, method: "receive_a_gift" }],
  },
];

export const UserLevelPage = ({ getUserLevel, userLevel, language }) => {
  const [currentTab, setCurrentTab] = useState("charge");
  const [theme, setTheme] = useState("green");
  const [tabs] = useState(["charge", "personal", "carisma"]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    switch (currentTab) {
      case "personal":
        setTheme("blue");
        break;
      case "charge":
        setTheme("green");
        break;
      case "carisma":
        setTheme("purple");
        break;
      default:
        break;
    }
  }, [currentTab]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getUserLevel(currentTab);
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <div className={`${styles.user__level__page} ${styles[theme]}`}>
      <div className={styles.overlay}>
        <img src={`/assets/svg/${theme}_up.svg`} alt="bg" />
      </div>
      <div className={styles.content}>
        <UserInfoHeader no_bg loading={loading} data={userLevel} level />
        <Wrapper white_shadow>
          <NavigationBar
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {/* <p className={styles.remaining}>
            <span>
              {userLevel?.level?.current_level_target -
                userLevel?.level?.current_level_exp}
            </span>
            {userLevel?.level?.next_level_name
              ? "XP Remaining to reach the next level"
              : "XP Remaining to finish this level"}
          </p> */}
          <div className={styles.user__levels}>
            <div className={styles.level}>
              <div className={styles.level__badge__wrapper}>
                {/* <img
                  width={60}
                  src={userLevel?.level?.curretn_level_img}
                  alt="level"
                /> */}
                <p className={styles.level__name}>
                  {userLevel?.level?.current_level_name}
                </p>
              </div>
            </div>
            <UserLevelProgressBar
              loading={loading}
              data={userLevel}
              language={language}
            />
            {userLevel?.level?.next_level_name && (
              <div className={styles.level}>
                <div className={styles.level__badge__wrapper}>
                  {/* <img
                    width={60}
                    src={userLevel?.level?.next_level_img}
                    alt="level"
                  /> */}
                  <p className={styles.level__name}>
                    {userLevel?.level?.next_level_name}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className={styles.upgrade__methods__wrapper}>
            <p className={styles.list__title}>{t("upgrade_level")}</p>
            <ul className={styles.upgrade__methods__list}>
              {upgradeLevelData
                .filter((item) => item.level === currentTab)[0]
                .data.map((data, index) => (
                  <li key={index} className={styles.upgrade__methods__item}>
                    <UpgradeMethodCard data={data} />
                  </li>
                ))}
            </ul>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLevelPage);
