import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";

import UserInfoHeader from "../../components/UserInfoHeader";
import Wrapper from "../../components/Wrapper";
import NavigationBar from "../../components/NavigationBar";
import BadgeCard from "../../components/BadgeCard";
import { getUserBadges } from "../../redux/actions";
import BadgesPopUp from "./BadgesPopUp";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { getUserBadges };

export const UserBadgesPage = ({ userBadges, getUserBadges }) => {
  const [currentTab, setCurrentTab] = useState("achievement");
  const [tabs] = useState(["achievement", "gift", "charge"]);
  const [loading, setLoading] = useState(true);
  const [wearedBadges, setWearedBadges] = useState([]);

  const fillArray = (array) => {
    while (array?.length < 3) {
      array?.push({});
    }
    return array;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getUserBadges(currentTab);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    setWearedBadges(
      fillArray(userBadges?.badges?.filter((item) => item.isWorn))
    );
  }, [userBadges]);

  return (
    <div className={styles.user__badges__page}>
      <BadgesPopUp
        action={() => getUserBadges(currentTab)}
        fillArray={fillArray}
        wearedBadges={wearedBadges}
        setWearedBadges={setWearedBadges}
        title={"Select Badge To Wear "}
        id="badges__pop__up"
      />
      <UserInfoHeader
        loading={loading}
        data={userBadges}
        badges={wearedBadges}
      />
      <Wrapper>
        <NavigationBar
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        <div className="pt-5 pb-3">
          <div className="row px-2">
            {!loading &&
              userBadges?.badges?.length &&
              userBadges?.badges?.map((item, index) => (
                <div key={index} className={`col-4 ${styles.col}`}>
                  <BadgeCard key={index} data={item} />
                </div>
              ))}
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(UserBadgesPage);
