import API from "../../api";
// import { TOGGLE__BADGE__WEAR } from "../../constants";

const toggleBadgeWear = (id) => async (dispatch) => {
  try {
    const res = await API.post(`wear-badge/${id}`);
    return res;
  } catch (error) {
    return error.response;
  }
};
export default toggleBadgeWear;
