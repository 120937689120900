import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const NavigationBar = ({ tabs, currentTab, setCurrentTab }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.nav__bar}>
      {tabs.map((item, index) => (
        <button
          onClick={() => {
            setCurrentTab(item);
          }}
          className={`${styles.nav__btn} ${
            currentTab === item && styles.active
          }`}
          key={index}
        >
          {t(item)}
        </button>
      ))}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
