import React from "react";
import styles from "./.module.scss";

export default function LoadingWrapper({ theme }) {
  return (
    <div className={styles.loading__wrapper__}>
      <div
        style={{ width: "3rem", height: "3rem" }}
        className={`spinner-border ${styles[theme]}`}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
