import API from "../../api";
import { GET__BADGE__DETAILS } from "../../constants";

const getBadgeDetails = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/get-badge-review/${id}`);
    dispatch({
      type: GET__BADGE__DETAILS,
      payload: res.data.data.badge,
    });
  } catch (error) {
    dispatch({
      type: GET__BADGE__DETAILS,
      payload: error.response.data || {},
    });
  }
};
export default getBadgeDetails;
