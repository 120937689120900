import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.scss";
import "../../styles/normalize.scss";
import styles from "./.module.scss";
import { getTopRankings } from "../../redux/actions";

// COMPONENTS
import Filter from "./Filter";
import Navigation from "./Navigation";
import TopRanked from "./TopRanked";
import RankList from "./RankList";
import UserRank from "./UserRank";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { getTopRankings };

export const RankingsPage = ({ getTopRankings, topRankings }) => {
  const [loading, setLoading] = useState(true);

  // States
  const [type, setType] = useState("rooms");
  const [range, setRange] = useState("daily");
  const [theme, setTheme] = useState("blue");

  // DATA
  const filterTypeBtns = [
    { theme: "blue", item: "rooms", param: "rooms" },
    { theme: "green", item: "gift-senders", param: "gift-senders" },
    { theme: "purple", item: "gift-receivers", param: "gift-receivers" },
    { theme: "yellow", item: "wallet-recharges", param: "wallet-recharges" },
  ];
  const themes = ["blue", "green", "purple", "yellow"];
  const filterBtns = [
    { item: "daily", theme },
    { item: "weekly", theme },
    { item: "monthly", theme },
  ];

  console.log(topRankings);
  useEffect(() => {
    (async () => {
      setLoading(true);
      await getTopRankings({ type, range, limit: 7 });
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, type]);

  useEffect(() => {
    for (let i = 0; i < themes.length; i++) {
      const element = themes[i];
      document.body.classList.remove(`${element}__theme`);
    }

    document.body.classList.add(`${theme}__theme`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return (
    <div className={`${styles.app__container} ${styles[`${theme}__theme`]}`}>
      <div className={styles.img__bg__overlay}>
        <img
          className={styles.up}
          src={`/assets/svg/${theme}_up.svg`}
          alt="bg"
        />
        <img
          className={styles.down}
          src={`/assets/svg/${theme}_down.svg`}
          alt="bg"
        />
      </div>
      <div className={styles.app__wrapper}>
        <div className={`container ${styles.container}`}>
          <Navigation />
          <Filter
            loading={loading}
            setTheme={setTheme}
            current={type}
            action={setType}
            data={filterTypeBtns}
          />
          <Filter
            loading={loading}
            setTheme={setTheme}
            current={range}
            action={setRange}
            data={filterBtns}
          />
          <TopRanked
            loading={loading}
            topRankings={topRankings}
            theme={theme}
          />
          <RankList loading={loading} topRankings={topRankings} theme={theme} />
          <UserRank
            type={type}
            loading={loading}
            rank={topRankings?.current}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(RankingsPage);
