import API from "../../api";
import { GET__USER__WALLET } from "../../constants";

const getUesrWallet = () => async (dispatch) => {
  try {
    const res = await API.get(`/wallet/get-wallet`);
    dispatch({
      type: GET__USER__WALLET,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET__USER__WALLET,
      payload: error.response.data || {},
    });
  }
};
export default getUesrWallet;
