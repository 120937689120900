import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const PackageCard = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.package__card}>
      <div className={styles.package__coins__number}>
        <span className={styles.diamond__icon}>
          <img
            width={50}
            className={styles.icon}
            src="/assets/icons/diamond.svg"
            alt="diamond__icon"
          />
        </span>
        <span className={styles.pakage__quantity}>{item.count_coin}</span>
      </div>
      <button className={styles.buy__package__btn}>
        {t("buy_for")} {item.price}$
      </button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageCard);
