import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { AiOutlinePlus } from "react-icons/ai";
import { isEmpty } from "lodash";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const UserInfoHeader = ({ no_bg, level, badges, data, loading }) => {
  return (
    <header className={`${styles.user__info__header} ${no_bg && styles.no_bg}`}>
      <div className="container">
        <div className={styles.info__wrapper}>
          <div className={styles.user__avatar__wrapper}>
            {!loading && <img src={data.user.avatar} alt="avatar" />}
          </div>
          <div className={styles.user__details}>
            <p className={styles.user__name}>
              {!loading ? data?.user?.name : "Loading ..."}
            </p>
            {level && !loading && (
              <div className={styles.current__level}>
                <img src={data?.level?.curretn_level_img} alt="" />
                {/* <span className={styles.user__level}>
                  {!loading
                    ? `LV. ${data?.level?.current_level_number}`
                    : "Loading ..."}
                </span> */}
              </div>
            )}
            {badges && (
              <div className={styles.user__badges__actions}>
                {badges
                  .filter((_, i) => i <= 2)
                  .map((badge, index) => {
                    return isEmpty(badge) ? (
                      <button
                        key={index}
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#badges__pop__up"
                        className={styles.badge__btn}
                      >
                        <AiOutlinePlus />
                      </button>
                    ) : (
                      <div className={styles.badge__btn}>
                        <img src={badge.icon} alt={badge.name} />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoHeader);
