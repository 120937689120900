import axios from "axios";
import queryString from "query-string";
import { API_URL } from "../config";
import Cookies from "js-cookie";

const TOKEN = Cookies.get("TOKEN");
const params = queryString.parse(window.location.search);
const token = params.token;

export default axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${TOKEN || token}`,
  },
});
