import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const UserLevelProgressBar = ({
  noEXP,
  details,
  color,
  data,
  loading,
  language,
}) => {
  const { t } = useTranslation();
  const precentage = () => {
    if (!isEmpty(data?.level)) {
      return (
        Math.floor(
          (data?.level?.current_level_exp / data?.level?.current_level_target) *
            100
        ) || Math.floor((data?.daily_login_count / data?.tasks) * 100)
      );
    } else {
      if (data?.daily_login_count > data?.tasks) {
        return Math.floor((data?.tasks / data?.tasks) * 100);
      } else {
        return Math.floor((data?.daily_login_count / data?.tasks) * 100);
      }
    }
  };
  return (
    <div
      className={`${styles.user__level__progress__bar} ${color && styles.gold}`}
    >
      <div className={styles.progress__bar__wrap}>
        <div className={styles.progress__bar}></div>
        {!loading && (
          <div
            style={{
              width: `${precentage() > 100 ? 100 : precentage() || 0.001}%`,
            }}
            className={`${styles.progress__completed} ${styles[language]}`}
          >
            <span className="">{precentage() || 0}%</span>
          </div>
        )}
        {!noEXP && !isEmpty(data?.level) ? (
          <p
            style={{ fontSize: "12px", textAlign: "center" }}
            className={styles.user__XP}
          >
            <span className={"mx-2"}>
              {data?.level?.current_level_target -
                data?.level?.current_level_exp}
            </span>{" "}
            {t("points_needed")}
          </p>
        ) : (
          <>
            <p className={styles.user__XP}>
              <span>
                {data?.level?.current_level_exp ||
                  (data?.daily_login_count > data?.tasks
                    ? data.tasks
                    : data?.daily_login_count) ||
                  0}
              </span>{" "}
              / {data?.level?.current_level_target || data?.tasks || 0} XP
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLevelProgressBar);
