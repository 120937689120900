import React from "react";
import styles from "./.module.scss";
import approx from "approximate-number";

export default function RankRow({ theme, rank, placeholder }) {
  return (
    <div
      className={`${styles.rank__row} ${styles[theme]} ${
        placeholder ? styles.placeholder : null
      }`}
    >
      {!placeholder ? (
        <>
          <div className={styles.rank__info}>
            {<span className={styles.rank__number}>{rank.rank}</span>}
            <div className={styles.rank__user__avatar}>
              <img
                src={
                  // rank?.user?.profile_photo ||
                  rank?.room_uploaded_avatar ||
                  rank?.user?.profile_photo ||
                  // rank?.user_avatar_path ||
                  // rank?.receiver_avatar_path ||
                  `/assets/svg/user.png`
                }
                alt="user"
              />
            </div>
            <p className={styles.rank__username}>
              {rank?.room_name || rank?.receiver_name || rank?.user_name}
            </p>
          </div>
          <div className={styles.achievements}>
            {rank?.userBadges?.map((item) => (
              <span key={item}>
                <img
                  width={25}
                  src={item?.badge_icon || item?.badge_img}
                  alt="icon"
                />
              </span>
            ))}
          </div>
          <div className={styles.coins}>
            <span>{approx(rank?.total_coins, { capital: true })}</span>
            <img width={20} src="/assets/svg/icons/coin.svg" alt="coin" />
          </div>
        </>
      ) : null}
    </div>
  );
}
