import API from "../../api";
import { GET__USER__TRANSACTIONS } from "../../constants";

const getUserTransactions = (filterParam) => async (dispatch) => {
  try {
    const res = await API.get(
      `/wallet-transaction/get-wallet-transaction?transactionType=${filterParam}`
    );
    dispatch({
      type: GET__USER__TRANSACTIONS,
      payload: res?.data?.data,
    });
  } catch (error) {
    dispatch({
      type: GET__USER__TRANSACTIONS,
      payload: error?.response?.data || [],
    });
  }
};
export default getUserTransactions;
