import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const UpgradeMathodCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.upgrade__method__card}>
      <div className={styles.details}>
        <div className={styles.icon__wrapper}>
          <img width={38} src="/assets/icons/gift.svg" alt="icon" />
        </div>
        <div className={styles.card__facts}>
          <p>{t(data.method)}</p>
          <span>
            {data.coin} {t("coin")} = {data.xp} {t("xp")}
          </span>
        </div>
      </div>
      <div className={styles.quantity}>
        <img width={45} src="/assets/icons/diamond.svg" alt="icon" />
        <span>{data.coin}</span>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeMathodCard);
