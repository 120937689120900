import { GET__TOP__RANKINGS } from "../../constants";

const topRankingsReducer = (topRankings = {}, action) => {
  switch (action.type) {
    case GET__TOP__RANKINGS:
      return action.payload;
    default:
      return topRankings;
  }
};
export default topRankingsReducer;
