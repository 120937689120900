import React from "react";
import { connect } from "react-redux";
import Modal from "../../../components/Modal";
import BadgeCard from "../../../components/BadgeCard";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const BadgesPopUp = ({
  id,
  title,
  userBadges,
  wearedBadges,
  setWearedBadges,
  fillArray,
  action,
}) => {
  const { t } = useTranslation();
  return (
    <Modal title={t("badge_to_wear")} id={id}>
      <div className="row">
        {userBadges?.badges
          ?.filter((i) => i.isAvailable && !i.isWorn)
          ?.map((item) => (
            <div className={`col-4 ${styles.card__wrapper}`}>
              <BadgeCard
                action={action}
                fillArray={fillArray}
                wearedBadges={wearedBadges}
                setWearedBadges={setWearedBadges}
                ModalBtn={true}
                data={item}
              />
            </div>
          ))}
        {!userBadges?.badges?.filter((i) => i.isAvailable && !i.isWorn)
          .length && (
          <div className={styles.no__badges}>
            <p>{t("no_badges")}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgesPopUp);
