import { GET__USER__LEVEL } from "../../constants";

const userLevelReducer = (userLevel = {}, action) => {
  switch (action.type) {
    case GET__USER__LEVEL:
      return {
        ...userLevel,
        user: {
          name: action.payload.levels?.currentLevel.user.full_name,
          avatar: action.payload.levels?.currentLevel.user.profile_photo,
        },
        level: {
          curretn_level_img:
            action.payload.levels?.currentLevel?.currentLevel.level_img,
          current_level_id:
            action.payload.levels?.currentLevel?.currentLevel.id,
          current_level_number:
            action.payload.levels?.currentLevel?.currentLevel.level_number,
          current_level_name:
            action.payload.levels?.currentLevel?.currentLevel.name,
          current_level_exp: action.payload.levels?.currentLevel.coins,
          current_level_target:
            action.payload.levels?.currentLevel?.currentLevel?.exp,

          next_level_id: action.payload.levels?.nextLevel?.id,
          next_level_number: action.payload.levels?.nextLevel?.level_number,
          next_level_exp: action.payload.levels?.nextLevel?.exp,
          next_level_img: action.payload.levels?.nextLevel?.level_img,
          next_level_name: action.payload.levels?.nextLevel?.name,
        },
      };
    default:
      return userLevel;
  }
};
export default userLevelReducer;
