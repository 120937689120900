import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { getUesrWallet } from "../../redux/actions";
import moment from "moment";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { getUesrWallet };

export const UserBalanceWrapper = ({ userWallet, getUesrWallet }) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getUesrWallet();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <img src="/assets/icons/circle.svg" alt="bg" />
      </div>
      <div className={styles.user__balance__wrapper}>
        <p className={styles.wrapper__title}>{t("my_balance")}</p>
        <div className={styles.balance__wrapper}>
          <span className={styles.icon}>
            <img
              width={55}
              src="/assets/icons/diamond.svg"
              alt="diamond__icon"
            />
          </span>
          {
            <span className={styles.balance}>
              {!loading ? userWallet?.balance : t("loading")}
            </span>
          }
        </div>
        {
          <p className={styles.last__update__date}>
            {userWallet?.last_recharge_date
              ? `${t("last_recharge")} ${moment(
                  userWallet?.last_recharge_date
                ).format("LL")}`
              : t("no_charge_trans")}
          </p>
        }
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBalanceWrapper);
