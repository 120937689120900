import { GET__USER__WALLET } from "../../constants";

const userWalletReducer = (
  userWallet = { balance: 0, last_recharge_date: null },
  action
) => {
  switch (action.type) {
    case GET__USER__WALLET:
      return {
        ...userWallet,
        balance: action.payload.balance,
        last_recharge_date: action.payload.last_recharge_date,
      };
    default:
      return userWallet;
  }
};
export default userWalletReducer;
