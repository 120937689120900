import React from "react";
import styles from "./.module.scss";
import RankRow from "./RankRow";

export default function RankList({ theme, topRankings, loading }) {
  return (
    <div className={styles.rank__list}>
      {!loading
        ? topRankings?.data
            ?.filter((i) => i.rank > 3)
            .map((rank) => (
              <RankRow key={rank.rank} rank={rank} theme={theme} />
            ))
        : [1, 2, 3, 4].map((rank) => (
            <RankRow key={rank.rank} placeholder theme={theme} />
          ))}
    </div>
  );
}
