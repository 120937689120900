import API from "../../api";
import { GET__USER__BADGES } from "../../constants";

const getUserBadges = (currentTab) => async (dispatch) => {
  const setParam = () => {
    switch (currentTab) {
      case "achievement":
        return "/get-badges/achievement";
      default:
        break;
    }
  };
  try {
    const res = await API.get(setParam());
    dispatch({
      type: GET__USER__BADGES,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET__USER__BADGES,
      payload: error.response.data || {},
    });
  }
};
export default getUserBadges;
