import React from "react";
import styles from "./.module.scss";

export default function LoadSpinner() {
  return (
    <div className={styles.loader__wrapper}>
      <div className={styles.loader}></div>
    </div>
  );
}
