const GET__USER__WALLET = "GET__USER__WALLET";
const GET__ALL__RECHARGE__PACKAGES = "GET__ALL__RECHARGE__PACKAGES";
const GET__USER__TRANSACTIONS = "GET__USER__TRANSACTIONS";
const GET__USER__LEVEL = "GET__USER__LEVEL";
const GET__USER__BADGES = "GET__USER__BADGES";
const GET__BADGE__DETAILS = "GET__BADGE__DETAILS";
const TOGGLE__BADGE__WEAR = "TOGGLE__BADGE__WEAR";
const GET__TOP__RANKINGS = "GET__TOP__RANKINGS";

export {
  GET__USER__WALLET,
  GET__ALL__RECHARGE__PACKAGES,
  GET__USER__TRANSACTIONS,
  GET__USER__LEVEL,
  GET__USER__BADGES,
  GET__BADGE__DETAILS,
  TOGGLE__BADGE__WEAR,
  GET__TOP__RANKINGS,
};
