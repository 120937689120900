import { GET__USER__TRANSACTIONS } from "../../constants";

const userTransactionsReducer = (userTransactions = [], action) => {
  switch (action.type) {
    case GET__USER__TRANSACTIONS:
      return action.payload;
    default:
      return userTransactions;
  }
};
export default userTransactionsReducer;
