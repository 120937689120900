import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
// Import Day.js and the required plugins
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";

// Import Arabic and English locales
import "dayjs/locale/ar";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";

// Extend Day.js with the plugins
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

// Function to set locale based on the state
const getLocale = (locale) => {
  if (locale === "ar") {
    dayjs.locale("ar"); // Set to Arabic
  } else {
    dayjs.locale("en"); // Set to English
  }
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const TransactionCard = ({
  transaction: { coin, type, price, created_at, user, receive },
  language,
}) => {
  getLocale(language);
  const { t } = useTranslation();
  const [data, setData] = useState({
    coin,
    type,
    icon: "diamond",
    content: "",
  });
  useEffect(() => {
    setData({
      ...data,
      cost: price,
      quantity:
        type === "sendGift" || type === "storeItem" || type === "sendItem"
          ? -coin
          : coin,
      icon:
        type?.includes("Gift") || type === "dailyLogin" ? "gift" : "diamond",
      content:
        type === "sendGift"
          ? t("send_gift")
          : type === "receiveGift"
          ? t("receive_gift")
          : type === "sendItem"
          ? t("send_item")
          : type === "dailyLogin"
          ? t("daily_login")
          : type === "charge" || type === "charge-free"
          ? t("charge_balance")
          : type === "retcharge" || type === "recharge"
          ? t("recharge_balance")
          : t("purchase_item"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.transaction__card}>
      <div className={styles.transaction__details}>
        <div className={styles.transaction__title__wrapper}>
          <img width={27} src={`/assets/icons/${data.icon}.svg`} alt="icon" />
          <p className={styles.transaction__title}>{data.content}</p>
        </div>
        <div className={styles.transaction__facts}>
          {(type === "sendGift" || type === "receiveGift") && (
            <span className={styles.transaction__id}>
              {type !== "sendGift" ? t("from_id") : t("to_id")}:{" "}
              {receive?.random_id}
            </span>
          )}
          {(type === "storeItem" || type === "dailyLogin") && (
            <span className={styles.transaction__id}>
              {t("user_id")} : {user?.random_id}
            </span>
          )}
          {Boolean(data.cost) && (
            <span className={styles.transaction__id}>
              {t("cost")} : {data.cost}
            </span>
          )}
          {Boolean(created_at) && (
            <span className={styles.transaction__id}>
              {dayjs(created_at).fromNow()}
            </span>
          )}
        </div>
      </div>
      <div
        className={`${styles.transaction__quantity} ${
          data.quantity <= 0 && styles.lose
        }`}
      >{`${data.quantity <= 0 ? data.quantity : `+${data.quantity}`}`}</div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionCard);
