import API from "../../api";
import { GET__TOP__RANKINGS } from "../../constants";

const getTopRankings = (options) => async (dispatch) => {
  try {
    const res = await API.get(
      `cup/${options.type}/${options.range}/?limit=${options.limit}`
    );
    dispatch({
      type: GET__TOP__RANKINGS,
      payload: {
        type: options.type,
        range: options.range,
        limit: options.limit,
        data: res?.data?.data?.top,
        current: res?.data?.data?.currentUserRank,
      },
    });
  } catch (error) {
    dispatch({
      type: GET__TOP__RANKINGS,
      payload: error?.response?.data || {},
    });
  }
};
export default getTopRankings;
