import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const Modal = ({ id, children, title }) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabindex="-1"
      aria-labelledby={`${id}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-6" id={`${id}Label`}>
              {title}
            </h1>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
