import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const Wrapper = ({ children, white_shadow }) => {
  return (
    <div className={`${styles.wrapper} ${white_shadow && styles.white_shadow}`}>
      <div className="container">{children}</div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
