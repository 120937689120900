import React from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { toggleBadgeWear } from "../../redux/actions";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { toggleBadgeWear };

export const BadgeCard = ({
  data,
  ModalBtn,
  wearedBadges,
  setWearedBadges,
  toggleBadgeWear,
  action,
}) => {
  const token = Cookies.get("TOKEN");
  return !ModalBtn ? (
    <Link
      to={`/badge-details/${data.id}?token=${token}`}
      className={`${styles.badge__card} ${
        !data.isAvailable && styles.disabled
      }`}
    >
      <img width={55} src={data.icon} alt={data.name} />
      <p className={styles.badge__name}>{data.name}</p>
    </Link>
  ) : (
    <div
      onClick={async () => {
        const { status } = await toggleBadgeWear(data?.id);
        if (status === 201) {
          wearedBadges.pop();
          wearedBadges.unshift(data);
          await action();
          setWearedBadges([...wearedBadges]);
        }
      }}
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#badges__pop__up"
      className={`${styles.badge__card} ${
        !data.isAvailable && styles.disabled
      }`}
    >
      <img width={55} src={data.icon} alt={data.name} />
      <p className={styles.badge__name}>{data.name}</p>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgeCard);
