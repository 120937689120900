import { GET__USER__BADGES } from "../../constants";

const userBadgesReducer = (userBadges = {}, action) => {
  switch (action.type) {
    case GET__USER__BADGES:
      return {
        ...userBadges,
        user: {
          name: action?.payload?.user?.full_name,
          avatar: action?.payload?.user?.profile_photo,
        },
        badges: action?.payload?.badges,
      };
    default:
      return userBadges;
  }
};
export default userBadgesReducer;
