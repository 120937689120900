import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";

import FilterBtn from "../../components/FilterBtn";
import TransactionCard from "../../components/TransactionCard";
import LoadSpinner from "../../components/LoadSpinner";
import { getUserTransactions } from "../../redux/actions";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { getUserTransactions };

const btnsData = [
  {
    id: 1,
    content: "all",
    active: true,
    param: "",
  },
  {
    id: 2,
    content: "gifts_log",
    active: false,
    param: "gift",
  },
  {
    id: 3,
    content: "charging_log",
    active: false,
    param: "retcharge",
  },
  {
    id: 4,
    content: "store_log",
    active: false,
    param: "storeItem",
  },
  {
    id: 4,
    content: "daily_log",
    active: false,
    param: "dailyLogin",
  },
];

export const UserTransactionsPage = ({
  getUserTransactions,
  userTransactions,
  language,
}) => {
  const [loading, setLoading] = useState(true);
  const [filterParam, setfilterParam] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getUserTransactions(filterParam);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParam]);

  return (
    <div className={styles.user__transactions__page}>
      <div className="container">
        <div className={styles.transactions__filter__btns}>
          {btnsData.map((btn, index) => (
            <FilterBtn
              key={index}
              param={btn.param}
              content={btn.content}
              onClick={setfilterParam}
              active={btn.param === filterParam}
            />
          ))}
        </div>
        {loading ? (
          <LoadSpinner />
        ) : userTransactions.length ? (
          userTransactions.reverse().map((set, index) => (
            <div key={index} className={styles.transactions__set}>
              <p className={styles.set_date}>{set.date}</p>
              <div className={styles.transactions__wrapper}>
                {set.transactions.reverse().map((transaction, index) => (
                  <TransactionCard
                    language={language}
                    key={index}
                    transaction={transaction}
                  />
                ))}
              </div>
            </div>
          ))
        ) : (
          <p className="py-3">{t("no_trans")}</p>
        )}
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTransactionsPage);
