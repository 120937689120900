import { GET__ALL__RECHARGE__PACKAGES } from "../../constants";

const rechargePackagesReducer = (rechargePackages = [], action) => {
  switch (action.type) {
    case GET__ALL__RECHARGE__PACKAGES:
      return action.payload;
    default:
      return rechargePackages;
  }
};
export default rechargePackagesReducer;
