import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./.module.scss";

import UserBalanceWrapper from "../../components/UserBalanceWrapper";
import PackageCard from "../../components/PackageCard";
import LoadSpinner from "../../components/LoadSpinner";

import { getAllRechargePackages } from "../../redux/actions";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { getAllRechargePackages };

export const UserWalletPage = ({
  getAllRechargePackages,
  rechargePackages,
}) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getAllRechargePackages();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.uesr__wallet__page}>
      <div className="container">
        <div className={styles.user__balance__row}>
          <UserBalanceWrapper />
        </div>
      </div>
      <div className={styles.user__balance__row}>
        <div className="container ">
          <h5 className={styles.title}>{t("recharge_packages")}</h5>
        </div>
        <div className="container p-0">
          <div className="row mx-1">
            {loading ? (
              <LoadSpinner />
            ) : !isEmpty(rechargePackages) && rechargePackages?.length ? (
              rechargePackages?.map((item, index) => {
                return (
                  <div key={index} className={`col-4 ${styles.card__wrapper}`}>
                    <PackageCard item={item} />
                  </div>
                );
              })
            ) : (
              <div>{t("no_charge_packages")}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserWalletPage);
