import React from "react";
import styles from "./.module.scss";
import { MdArrowBackIos } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function Navigation() {
  const { t } = useTranslation();
  return (
    <div className={styles.navigation}>
      <div className={styles.btn__wrapper}>
        {/* <button className={styles.back_btn}>
          <MdArrowBackIos className={styles.arrow_icon} />
        </button> */}
      </div>
      {/* <h2 className={styles.title}>{t("ranking")}</h2> */}
    </div>
  );
}
