import React from "react";
import styles from "./.module.scss";
import approx from "approximate-number";
import { BiErrorCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";

export default function UserRank({ type, theme, rank, loading }) {
  const { t } = useTranslation();
  return (
    <div className={`${styles.user__rank}  ${styles[theme]}`}>
      <div className={`container ${styles.container}`}>
        {loading ? (
          <div className={styles.no__data}>
            <div
              style={{ width: "2rem", height: "2rem" }}
              className={`spinner-border`}
              role="status"
            ></div>
          </div>
        ) : rank ? (
          <div className={styles.wrapper}>
            <div className={styles.rank__info}>
              {rank?.rank ? (
                <>
                  {type !== "rooms" ? (
                    <span className={styles.rank__number}>{rank?.rank}</span>
                  ) : null}
                </>
              ) : null}
              <div className={styles.rank__user__avatar}>
                <img
                  src={
                    rank?.user?.profile_photo ||
                    rank?.user_avatar_path?.path ||
                    rank?.room_uploaded_avatar ||
                    rank?.user_avatar_path ||
                    rank?.receiver_avatar_path ||
                    rank?.user?.user_avatar_path?.path ||
                    rank?.user?.room_uploaded_avatar ||
                    rank?.user?.user_avatar_path ||
                    rank?.user?.receiver_avatar_path ||
                    rank?.user?.receiver_avatar_path ||
                    rank?.user?.profile_photo ||
                    rank?.profile_photo ||
                    `/assets/svg/user.png`
                  }
                  alt="user"
                />
              </div>
              <p className={styles.rank__username}>
                {rank?.user?.full_name ||
                  rank?.room_name ||
                  rank?.receiver_name ||
                  rank?.user_name}
              </p>
            </div>
            <div className={styles.achievements}>
              {rank?.userBadges?.map((item) => (
                <span key={item}>
                  <img
                    width={25}
                    src={item?.badge_icon || item?.badge_img}
                    alt="icon"
                  />
                </span>
              ))}
            </div>
            {type !== "rooms" && (
              <div className={styles.coins}>
                <span>{approx(rank?.total_coins, { capital: true })}</span>
                <img width={20} src="/assets/svg/icons/coin.svg" alt="coin" />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.no__data}>
            <BiErrorCircle className={styles.icon} />
            <span>{t("no_data")}</span>
          </div>
        )}
      </div>
    </div>
  );
}
