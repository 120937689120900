import React from "react";
import styles from "./.module.scss";
import { useTranslation } from "react-i18next";

export default function Filter({ data, current, action, setTheme, loading }) {
  const { t } = useTranslation();
  return (
    <div className={styles.filter__wrapper}>
      <ul className={styles.filter__list}>
        {data.map((item, index) => (
          <li
            key={index}
            className={`${styles.list__item} ${styles[item.theme]} ${
              item.item === current ? styles.active : null
            } `}
          >
            <button
              disabled={loading}
              onClick={() => {
                action(item?.item);
                item?.theme && setTheme(item?.theme);
              }}
              className={styles.filter__btn}
            >
              <span>{t(`${item.item}`)}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
