import { GET__BADGE__DETAILS } from "../../constants";

const badgeDetailsReducer = (badgeDetails = {}, action) => {
  switch (action.type) {
    case GET__BADGE__DETAILS:
      return {
        ...badgeDetails,
        ...action.payload,
      };
    default:
      return badgeDetails;
  }
};
export default badgeDetailsReducer;
