import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./.module.scss";
import UserLevelProgressBar from "../../components/UserLevelProgressBar";
import { getBadgeDetails, toggleBadgeWear } from "../../redux/actions";
// import { Svga } from "react-svga";
import { isEmpty } from "lodash";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { getBadgeDetails, toggleBadgeWear };

export const BadgeDetailsPage = ({
  getBadgeDetails,
  badgeDetails,
  toggleBadgeWear,
}) => {
  const [badgeWearBtn, setBadgeWearBtn] = useState(badgeDetails.isWorn);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (id) {
        await getBadgeDetails(id);
      }
      setLoading(false);
    })();

    setBadgeWearBtn(badgeDetails.isWorn);
  }, [badgeDetails.isWorn, getBadgeDetails, id]);

  return (
    <div className={styles.badge__details__page}>
      <div className="container">
        <div className={styles.badge__icon__wrapper}>
          <div className={styles.blur__layer}></div>
          <div className={styles.badge__icon}>
            {!loading && (
              <div
                className={`${styles.svga__wrapper} ${
                  !badgeDetails.isAvailable && styles.disabled
                }`}
              >
                <img
                  src={
                    badgeDetails?.img ||
                    badgeDetails?.image ||
                    badgeDetails?.icon
                  }
                  alt="img"
                />
              </div>
            )}
          </div>
        </div>
        {!loading && (
          <>
            <div className={styles.badge__info}>
              <p>{badgeDetails.tasks} Times Of Sign In</p>
            </div>
            <div className={styles.progress__bar__wrapper}>
              <UserLevelProgressBar data={badgeDetails} color="gold" />
            </div>
          </>
        )}
        <div className={styles.badges__exp}>
          {!isEmpty(badgeDetails) && badgeDetails.isAvailable && (
            <button
              onClick={async () => {
                await toggleBadgeWear(badgeDetails.id);
                setBadgeWearBtn(!badgeWearBtn);
              }}
              className={`${styles.wear__badge__btn} ${
                !badgeWearBtn && styles.active
              }`}
            >
              {!badgeWearBtn ? "Get Badge" : "Remove Badge"}
            </button>
          )}
        </div>
        {/* <div className={styles.badges__exp}> */}
        {/* {items.map((item, index) => (
            <div key={index} className={styles.badge__wrapper}>
              <img
                width={60}
                src={`/assets/icons/icon-test-${item.icon}.png`}
                alt="badge"
              />
              <div className={styles.exp}>
                <span className={styles.icon}>
                  <img src="/assets/icons/quality.png" alt="icon" />
                </span>
                <span className={styles.quantity}>{item.quantity}</span>
              </div>
            </div>
          ))} */}
        {/* <div className={styles.badge__wrapper}>
            <img width={60} src="/assets/icons/badge-details.png" alt="badge" />
            <div className={styles.exp}>
              <span className={styles.icon}>
                <img src="/assets/icons/quality.png" alt="icon" />
              </span>
              <span className={styles.quantity}>25</span>
            </div>
          </div> */}
        {/* <div className={styles.badge__wrapper}>
            <img width={60} src="/assets/icons/badge-details.png" alt="badge" />
            <div className={styles.exp}>
              <span className={styles.icon}>
                <img src="/assets/icons/quality.png" alt="icon" />
              </span>
              <span className={styles.quantity}>25</span>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgeDetailsPage);
